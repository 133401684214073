<script>
import store from '@/store'

export default {
    data() {
        return {
            trackNo: '',
            controlResult: null,
            controlResultWatcher: null
        }
    },
    methods: {
        watchControlResult() {
            this.controlResultWatcher = store.watch(
                (state, getters) => getters.controlResult,
                (val) => {
                    if (val) { this.controlResult = val }
                }
            )
        },
        checkControl() {
            let door
            if (this.controlResult) door = this.controlResult[this.trackNo]
            if (door > 0) { this.doorId = door;  return true; }
            else if (door === 0) store.commit('setSnackBar', { message: '無儲格可使用', color: 'error' })
            else {
                store.commit('setSnackBar', { message: '控制失敗，請稍後再試', color: 'error' })
            }
            return false
        }
    },
    activated() {
        this.watchControlResult()
    },
    deactivated() {
        // unwatch control result
        if (this.controlResultWatcher) this.controlResultWatcher()
    }
}
</script>
