<template>
    <v-card flat>
        <v-card-title class="subheading pa-1 pt-0">
            {{ $t('doorSelector.cabinet-number') }}&nbsp;&nbsp;{{ boxId }}
            <v-btn small outline color="primary" class="body-2" @click="back">{{ $t('doorSelector.reselect') }}</v-btn>
        </v-card-title>
        <v-card-text class="pa-1 pt-0">
            <v-alert v-show="warnMsg" color="error" outline class="subheading">
                <b>{{warnMsg}}</b>
            </v-alert>
            <v-container grid-list-xs fluid class="pa-0">
                <v-layout row wrap>
                    <v-flex v-for="door in availableDoors" :key="door.id" xs3 sm2 md2 lg1>
                        <v-card class="clickable" @click="select(door)" :dark="door.id === doorId">
                            <v-card-title class="justify-center title">{{door.id}}</v-card-title>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
    </v-card>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import { HostUrl } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { HOMETYPES } from '@/utils/utils'
import i18n from '@/i18n'

export default {
    name: 'door-selector',
    components: { WaitingDialog },
    mixins: [ WaitDialogControl ],
    props: {
        enable: {
            type: Boolean,
            default: false
        },
        boxId: {
            type: String,
            required: true
        },
        doorId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            preDoorId: 0,
            availableDoors: [],
            rate: null,
            warnMsg: null
        }
    },
    watch: {
        enable(val) {
            if (val) this.getAvailableDoors()
            else this.init()
        },
        doorId(val, oldVal) {
            this.preDoorId = oldVal
        }
    },
    methods: {
        init() {
            this.availableDoors = []
            this.rate = null
            this.warnMsg = null
        },
        back() {
            this.init()
            this.$emit('update:doorId', 0)
            this.$emit('back')
        },
        select(door) {
            this.$emit('update:doorId', door.id)
            this.checkDoorAvailability(door.id)
        },
        checkDoorAvailability(doorId, user=null) {
            let doorInfo = this.availableDoors.find(d => d.id === doorId)
            if (doorInfo) {
                this.warnMsg = null
                this.$emit('select', doorInfo, this.rate, true)
            } else if (this.availableDoors.length > 0 && doorId > 0) {
                if (user) {
                    this.warnMsg = `${i18n.t('doorSelector.had-putin')}` + ' [' + `${doorId}` +'], ' + `${i18n.t('doorSelector.or-takeout')}`
                    this.$emit('select', null, this.rate, false)
                } else {
                    this.warnMsg = `${i18n.t('doorSelector.cell')} ${doorId} ${i18n.t('doorSelector.unavailable')}`
                    this.$emit('select', null, this.rate, false)
                }
            } else if (this.availableDoors.length === 0) {
                this.warnMsg = i18n.t('doorSelector.no-cell')
                this.$emit('select', null, this.rate, false)
            } // else this.doorId === 0 do nothing
        },
        getAvailableDoors() {
            if (!this.boxId) {
                this.warnMsg = i18n.t('doorSelector.cabinet-not-set')
                return
            }
            this.showDialog(i18n.t('doorSelector.load-cell-status'), i18n.t('doorSelector.load-fail'))
            let url = `${HostUrl}/api/box/${this.boxId}/doors`
            if (store.getters.pageHome > HOMETYPES.STORE) {
                url = `${HostUrl}/api/box/${this.boxId}/`+store.getters.pageHome+`/doors`
            }
            let self = this
            axios.get(url, getAxiosConfig())
                .then(function(response) {
                    if (response.data.code !== 0) throw Error(response.data.message)
                    self.rate = response.data.rate
                    let doors = response.data.doors
                    let is_availableDoors = Object.keys(doors).filter((doorId) => {
                        return doors[doorId].is_available
                    })
                    if (is_availableDoors.length === 0) {
                        self.warnMsg = i18n.t('doorSelector.no-cell')
                        return
                    }
                    let user_dict = new Object()
                    for (const key of Object.keys(doors)) {
                        if (doors[key].user)
                            user_dict[key] = true
                    }
                    
                    //檢查 doorId 不為 0，轉換成整數(以10進位)
                    self.availableDoors = is_availableDoors.map((doorId) => {
                        if(doorId !== 0){
                            doors[doorId].id = parseInt(doorId, 10)
                            return doors[doorId]
                        } 
                    })
                    if (self.preDoorId === 0 && self.doorId > 0) {
                        if (user_dict[self.doorId.toString()])
                            self.checkDoorAvailability(self.doorId, true)
                        else
                            self.checkDoorAvailability(self.doorId)
                        self.preDoorId = self.doorId
                    }
                })
                .catch(function(error) {
                    if (error.message === 'FULL') {
                        self.warnMsg = `${i18n.t('doorSelector.cell')} ${self.doorId} ${i18n.t('doorSelector.had-putin')}`
                    } else
                        self.warnMsg = error.message
                })
                .finally(function() {
                    self.closeDialog()
                })
        }
    }
}
</script>

<style scoped>
</style>
