<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <QRReader :camera="camera" @query="onQuery"></QRReader>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-1" >
                <v-layout justify-center v-show="isFromCamera">
                    <v-flex xs12 sm10 md8 lg8>
                        <door-selector
                            :enable="stepNo === 2 && isFromCamera"
                            :box-id="boxId"
                            :door-id.sync="doorId"                            
                            @select="selectDoorByStaff"
                            @back="preStep"                            
                        ></door-selector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-1" >
                <v-layout justify-center v-show="!isFromCamera">
                    <v-flex xs12 sm10 md8 lg8>
                        <door-multi-selector
                            :enable="stepNo === 2 && !isFromCamera"
                            :box-id="boxId"
                            :door-id.sync="doorId"
                            :doors.sync="doors"
                            @select="selectDoorByStaff"
                            @back="preStep"
                            @selectMany="selectMultiDoorByStaff"
                        ></door-multi-selector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>

            <v-stepper-content step="3" v-if="isFromCamera">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-chip label color="green" text-color="white" class="title">
                            {{$t(`storehostPutin.cell`)}}&nbsp;&nbsp;<b>{{doorId}}</b>
                        </v-chip>
                        <v-form class="mt-2" ref="form" v-model="receiver.valid">
                            <v-text-field outline clearable prepend-icon="shop" name="shop" :label="$t(`storehostPutin.goods-info`)"
                                          type="text" required v-model="receiver.note" data-cy="noteField">
                            </v-text-field>
                            <v-text-field outline clearable prepend-icon="monetization_on" name="money" :label="$t(`storehostPutin.good-price`)"
                                          type="text" required v-model="receiver.money" :rules="moneyRules" data-cy="moneyField">
                            </v-text-field>
                        </v-form>
                        <v-layout justify-center>
                            <v-btn flat large block color="primary" @click="setStepNo(2)">
                                <v-icon>arrow_back_ios</v-icon>{{$t(`storehostPutin.back`)}}
                            </v-btn>
                            <v-btn large block color="primary" :disabled="!receiver.valid" @click="controlDoor" data-cy="controlDoorBtn">{{$t(`paymentDetail.cells`)}}</v-btn>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
             <v-stepper-content step="3" v-if="!isFromCamera">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-list v-if="doors.length>0">
                            <template v-for="(door, index) in doors">
                                <v-list-tile avatar :key="index">
                                    <v-list-tile-content>
                                        <v-list-tile-title>{{ $t('doorSelector.cell') }}{{ door.id }}</v-list-tile-title>
                                        <v-list-tile-sub-title>{{ door.product_name || "" }}</v-list-tile-sub-title>
                                    </v-list-tile-content>
                                    <v-list-tile-action>
                                        <span>{{ door.fee || 0 }}{{ $t('paymentDetail.NTD') }}</span>
                                    </v-list-tile-action>
                                </v-list-tile>
                            </template>
                        </v-list>
                        <v-layout justify-center>
                            <v-btn flat large block color="primary" @click="setStepNo(2)">
                                <v-icon>arrow_back_ios</v-icon>{{$t(`storehostPutin.back`)}}
                            </v-btn>
                            <v-btn large block color="primary" @click="controlMultiDoor" data-cy="controlDoorBtn">{{$t(`paymentDetail.cells`)}}</v-btn>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4" v-if="isFromCamera">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-list subheader v-for=" cell in currentTracks" :key="cell.doorId">
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">{{$t(`putIn.cell-number`)}}&nbsp;&nbsp;<b>{{cell.doorId}}</b></v-list-tile-title>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <v-btn dark depressed color="cyan" class="subheading" @click="reopenBy(cell.trackNo,cell.doorId)" data-cy="reopenBtn">{{$t(`storehostPutin.close-door`)}}</v-btn>
                                </v-list-tile-action>
                            </v-list-tile>
                            <v-list-tile>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">{{$t(`storehostPutin.short-goods-info`)}}&nbsp;&nbsp;<b>{{receiver.note}}</b></v-list-tile-title>
                                </v-list-tile-content>
                                <v-list-tile-content>
                                    <v-list-tile-title class="subheading">{{$t(`storehostPutin.good-price`)}}&nbsp;&nbsp;<b>{{receiver.money}}</b></v-list-tile-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                        <v-alert :value="true" type="warning" class="subheading">{{$t(`putIn.close-door`)}}</v-alert>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn large outline class="body-2" color="success" @click="conti" data-cy="continueBtn">{{$t(`storehostPutin.continue-put-good`)}}</v-btn>
                    <v-btn large block class="ma-1" color="primary" @click="finish" data-cy="finishBtn">{{$t(`putIn.done`)}}</v-btn>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4" v-if="!isFromCamera">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-list v-if="doors.length>0">
                            <template v-for="(door, index) in doors">
                                <v-list-tile subheader :key="index">
                                    <v-list-tile-action>
                                        <v-btn dark depressed color="cyan" class="subheading" @click="reopenByDoor(door)" data-cy="reopenBtn">{{$t(`storehostPutin.close-door`)}}</v-btn>
                                    </v-list-tile-action>
                                    <v-list-tile-content>
                                        <v-list-tile-title><b>{{ door.id }}</b>{{ $t('doorSelector.cell') }}</v-list-tile-title>
                                    </v-list-tile-content>
                                    <v-list-tile-content>
                                        <v-list-tile-title>{{ typeName(door.product_name) }}</v-list-tile-title>
                                        <v-list-tile-sub-title>{{ door.fee || 0 }}{{ $t('paymentDetail.NTD') }}</v-list-tile-sub-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </template>
                        </v-list>
                        <v-alert :value="true" type="warning" class="subheading">{{$t(`putIn.close-door`)}}</v-alert>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn large outline class="body-2" color="success" @click="conti" data-cy="continueBtn">{{$t(`storehostPutin.continue-put-good`)}}</v-btn>
                    <v-btn large block class="ma-1" color="primary" @click="finish" data-cy="finishBtn">{{$t(`putIn.done`)}}</v-btn>
                </v-layout>
            </v-stepper-content>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import BoxIdWatcherMixin from '@/mixins/BoxIdWatcher'
import ControlMultiResultWatcher from '@/mixins/ControlMultiResultWatcher'
import PutinMixin from '@/mixins/PutinMixin'
import DoorMultiSelector from '@/components/DoorMultiSelector'
import { HostUrl } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'
import { publish, MQTT_ACTION } from '@/utils/MQTTClient.js'

export default {
    name: 'StorehostPutin',
    components:{DoorMultiSelector},
    mixins: [PutinMixin, BoxIdWatcherMixin, ControlMultiResultWatcher],
    data() {
        store.commit('setPageHome', 1)
        return {
            titles: [i18n.t('putIn.scan-code'), i18n.t('putIn.select-cell'), i18n.t('storehostPutin.goods-info'), i18n.t('storehostPutin.put-goods')],
            receiver: {
                valid: false,
                money: 0,
                note: ''
            },
            doors:[],
            moneyRules: [
                v => !!v || i18n.t('storehostPutin.input-goods-price'),
                v => /^[0-9].?[0-9]*/.test(v) || i18n.t('storehostPutin.input-goods-price')
            ]
        }
    },
    computed: {
        isFromCamera() {
            return this.boxId && this.doorId > 0 ;
        },
        currentTracks(){
            if (this.trackNo){
                const keys = Object.keys(this.trackNo);
                const result =  keys.map( key=> ({ trackNo:key, doorId: this.trackNo[key]['door_id']}));
                console.debug(result);
                return result;
            }
            else{
                return [];
            }
        }
    },
    methods: {
        initReceiver() {
            this.receiver = {
                valid: false,
                money: 0,
                note: ''
            }
        },
        init() {
            store.commit('initTransaction')
            store.commit('setTitle', i18n.t('putIn.scan-code'))
            store.commit('setTargetBoxId', '')
            this.boxId = ''
            this.preStepNo = 0
            this.stepNo = 1
            this.trackNo = null
            this.doorId = 0
            this.doors = [];
            this.doorInfo = null
            this.camera = 'auto'
            this.initReceiver()
        },
        onCameraQuery(query) {
            // console.debug('onCameraQuery',query);
            this.onQuery(query);
        },
        selectMultiDoorByStaff(doors) {
            // console.debug(doors);
            this.doors = doors;
            this.nextStep()
        },
        selectDoorByStaff(door, rate, isAvailable) {
            // console.debug(this,door,rate,isAvailable);
            if(isAvailable){
                this.receiver={
                    note: door.product_name || "",
                    money: door.fee || 0,
                    valid: false,
                }
                if( this.receiver.money > 0 && this.receiver.note !== ""){
                    this.receiver.valid=true;
                }
                this.selectDoor(door, rate)
                this.nextStep()
            }
        },
        typeName(door_name) {
            if (door_name) {
                if (door_name.length > 6) return door_name.substr(0, 6)
                else return door_name
            } else return ""
        },
        reopenByDoor(door){
            const trackinfo = this.currentTracks.find(a=>a.doorId === door.id);
            this.reopenBy(trackinfo.trackNo, trackinfo.doorId);
        },
        reopenBy(trackNo, doorId) {
            this.showDialog(i18n.t('putIn.door-control') + ' ' + doorId, i18n.t('putIn.door-control-fail'))
            var topic = 'box/' + store.getters.targetBoxId + '/trans/control';
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                action: MQTT_ACTION.PUTIN_ACTION,
                track_no: {[trackNo]: doorId}
            }, 1);
        },
        async controlMultiDoor() {
            let data = {}
            this.doors.forEach( door=>{
                data[door.id] = {
                    product_name: door.product_name || "",
                    fee: door.fee || 0
                };
            })
            this.putin(data);
        },
        async controlDoor() {
            let data = {
                [this.doorId]:{"product_name": this.receiver.note, "fee": this.receiver.money }
            }
            this.putin(data);
            
        },
        async putin(data){
            this.showDialog(i18n.t('putIn.door-control'), i18n.t('putIn.door-control-fail'))
            try {
                let response = await axios.post(HostUrl + '/api/storehost/putin', {
                    box_id: store.getters.targetBoxId,
                    package: data,
                    client_id: store.getters.clientId
                }, getAxiosConfig());
                if (response.data.code == 0) {
                    this.trackNo = response.data.track_nos;
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    });
                }
            } catch (error) {
                store.commit('setSnackBar', {
                    message: i18n.t('putIn.control-fail-message'),
                    color: 'error'
                });
            }
        },
        async conti() {
            this.showDialog(i18n.t('putIn.upload-data'), i18n.t('putIn.upload-data-fail'))
            try {
                let response = await axios.post(HostUrl + '/api/storehost/putin/continue', {
                    track_no: this.trackNo,
                    box_id: this.boxId
                }, getAxiosConfig())
                if (response.data.code == 0) {
                    this.closeDialog()
                    this.showDialog(i18n.t('putIn.loading-data'), i18n.t('putIn.loading-data-fail'))
                    setTimeout(() => {
                        this.doorId = 0
                        this.setStepNo(1)
                        this.closeDialog()
                    }, 5000)
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    })
                    this.closeDialog()
                }
            } catch (error) {
                store.commit('setSnackBar', {
                    message: i18n.t('putIn.upload-data-fail-message'),
                    color: 'error'
                })
                this.closeDialog()
            }
        },
        async finish() {
            this.showDialog(i18n.t('putIn.upload-data'), i18n.t('putIn.upload-data-fail'))
            try {
                let response = await axios.post(HostUrl + '/api/storehost/putin/finish', {
                    box_id: store.getters.targetBoxId
                }, getAxiosConfig());
                if (response.data.code == 0) {
                    this.initReceiver();
                    this.$router.push(`/${i18n.locale}/storehost/menu`);
                } else {
                    store.commit('setSnackBar', {
                        message: response.data.message,
                        color: 'error'
                    });
                }
            } catch (error) {
                store.commit('setSnackBar', {
                    message: i18n.t('putIn.upload-data-fail-message'),
                    color: 'error'
                });
            } finally {
                this.closeDialog()
            }
        }
    }
};
</script>

<style scoped>
</style>
