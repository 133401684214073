<script>
import store from '@/store'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
/**
 * 多儲格控制用結果檢視、原本的ControlResultWatcher只看單個
 */
export default {
    mixins: [ ControlResultWatcher ],
    data() {
        return {
            trackNo: [],
            controlResult: null,
            controlResultWatcher: null
        }
    },
    methods: {
        checkControl() {
            const trackNos = Object.keys(this.trackNo);
            console.debug(this.trackNo,trackNos);
            let nocell = false;
            let controlfail = false;
            trackNos.forEach( trackNo=> {
                if (this.controlResult) {
                   const door = this.controlResult[trackNo];
                    if( door === 0 ){
                        nocell = true;
                    }
                    else if( door < 0 ){
                        controlfail = true;
                    }

                }
            })
            if(nocell){
                store.commit('setSnackBar', { message: '無儲格可使用', color: 'error' });
                return false;
            }
            if(controlfail){
                store.commit('setSnackBar', { message: '控制失敗，請稍後再試', color: 'error' })
                return false;
            }
            return true;
        }
    },
}
</script>
