<template>
    <v-card flat>
        <v-card-title class="subheading pa-1 pt-0">
            {{ $t('doorSelector.cabinet-number') }}&nbsp;&nbsp;{{ boxId }}
            <v-btn small outline color="primary" class="body-2" @click="multi_back">{{ $t('doorSelector.reselect') }}</v-btn>
        </v-card-title>
        <v-card-text class="pa-1 pt-0">
            <v-alert v-show="warnMsg" color="error" outline class="subheading">
                <b>{{warnMsg}}</b>
            </v-alert>
            <template v-if="formatAvailableDoors.length===0">
                {{ $t('doorSelector.no-cell')  }}
            </template>
            <template v-if="formatAvailableDoors.length>0">
                
                <v-list>
                    <template v-for="(door, index) in formatAvailableDoors">
                        <v-list-tile avatar :key="index">
                            <v-list-tile-action>
                                    <v-checkbox color="indigo" v-model="door.selected"></v-checkbox>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title>{{ $t('doorSelector.cell') }}{{ door.id }}</v-list-tile-title>
                                <v-list-tile-sub-title>{{ door.product_name || "" }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                                <span>{{ door.fee || 0 }}{{ $t('paymentDetail.NTD') }}</span>
                            </v-list-tile-action>
                        </v-list-tile>
                    </template>
                </v-list>
            </template>
            <v-layout justify-center>
                <v-btn large block class="ma-1" color="primary" @click="selectMulti" :disabled="availableDoors.filter(a=>a.selected).length === 0" data-cy="finishBtn">{{ $t('putIn.select-cell')}}</v-btn>
            </v-layout>
        </v-card-text>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
    </v-card>
</template>

<script>
// import axios from 'axios'
import WaitingDialog from '@/components/WaitingDialog'
// import WaitDialogControl from '@/mixins/WaitDialogControl'
// import { HostUrl } from '@/store'
// import { getAxiosConfig } from '@/utils/AuthService'
// import i18n from '@/i18n'
import DoorSelector from '@/components/DoorSelector'
export default {
    name: 'door-multi-selector',
    components: { WaitingDialog },
    mixins: [ DoorSelector ],
    props: {
        doors: {
            type: Array,
        }
    },
    watch: {
        // 於getAvailableDoors最後一段有判斷、所以取消
        // availableDoors(val) {
        //     // 如果儲格資訊更新、且有指定doorId的話、直接觸發選擇動作
        //     if(this.doorId > 0 ){
        //         const door = val.find(a=>a.id === this.doorId);
        //         if(door){
        //             this.checkDoorAvailability(door.id);
        //         }
        //     }
        // }
    },
    computed:{
        formatAvailableDoors(){
            // 礙於測試資料問題、暫時不做過濾
            return this.availableDoors.filter(a=>a.fee > 0 && ( a.product_name !== null && a.product_name !== "") );
            // return this.availableDoors;
        }
    },
    methods: {
        multi_back() {
            this.$emit('update:doors', [])
            this.back();
        },
        selectMulti() {
            const selectedDoors = this.formatAvailableDoors.filter(a=>a.selected);

            this.$emit('update:doors', selectedDoors);
            this.$emit('selectMany', selectedDoors)
            // this.checkDoorAvailability(door.id)
        },
    }
}
</script>

<style scoped>
</style>
